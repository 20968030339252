import React, { useEffect, useState} from 'react';

const GenericInput=(props)=>{
    const [selectOptions,setSelectOptions]=useState([])
    const [r,setR]=useState('genericInputKey')
    const validateEmail=(email,validations)=> {
        if((!email||email=='')&&validations['mandatory']){
            let n=props.showError
            n[props.k]={required:true}
            props.setShowError(n)
            setR(new Date().getMilliseconds())
            return
        }
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email&&!re.test(String(email).toLowerCase())) {
            let n=props.showError
            n[props.k]={type:true}
            props.setShowError(n)
            setR(new Date().getMilliseconds())
            return
        } 
        let n=props.showError
        n[props.k]=false
        props.setShowError(n)
        setR(new Date().getMilliseconds())
    }
    function validateInteger(integer,validations) {
        if((!integer||integer=='')&&validations['mandatory']){
            let n=props.showError
            n[props.k]={required:true}
            props.setShowError(n)
            setR(new Date().getMilliseconds())
            return
        }
        if (integer&&validations['type'] == 'INTEGER') {
            integer = Number(integer)
            if (!Number.isInteger(integer)) {
                let n = props.showError
                n[props.k] = { type: true }
                props.setShowError(n)
                setR(new Date().getMilliseconds())
                return
            }
        }
        if (integer&&validations['min_value'] && integer < validations['min_value']) {
            let n=props.showError
            n[props.k]={min:true}
            props.setShowError(n)
            setR(new Date().getMilliseconds())
            return
        }
        if (validations['max_value'] && integer > validations['max_value']) {
            let n=props.showError
            n[props.k]={max:true}
            props.setShowError(n)
            setR(new Date().getMilliseconds())
            return
        }
        let n=props.showError
        n[props.k] = false
        props.setShowError(n)
        setR(new Date().getMilliseconds())
    }
    const getOptions=async(validations)=>{
        if (validations['allowed_values']) {
            setSelectOptions(validations['allowed_values']);
        } 
     else if(validations['fetch_allowed_values_from']){
        let a=await fetch(validations['fetch_allowed_values_from'])
        .then(response => response.json())
        .then(data => { setSelectOptions(data['response']['data'])});
     }
    }
    const validateString=(s,validations)=>{
        if((!s||s=='')&&validations['mandatory']){
            let n=props.showError
            n[props.k]={required:true}
            props.setShowError(n)
            setR(new Date().getMilliseconds())
            return
        }
        if(s&&validations['regex']){
            console.log(validations['regex'])
            let r=new RegExp(validations['regex'])
            if ((!s||s=='')&&!r.test(s)) {
                let n = props.showError
                n[props.k] = { regex: true }
                props.setShowError(n)
                setR(new Date().getMilliseconds())
                return
            }
            
        }
         if(s&&validations['min_length']&&s.length<validations['min_length']){
             let n = props.showError
             n[props.k] = { min: true }
             props.setShowError(n)
             setR(new Date().getMilliseconds())
         }else{
             let n = props.showError
             n[props.k] = false
             props.setShowError(n)
             setR(new Date().getMilliseconds())
         }
    }
    const validateENUM=(value,validation)=>{
        let n = props.showError
        console.log(validation,value,props.k)
        if((!value||value=='')&&validation['mandatory']){
             n[props.k] ={required:true}
             props.setShowError(n)
             setR(new Date().getMilliseconds())
            return
        }
        n[props.k] = false
        props.setShowError(n)
        setR(new Date().getMilliseconds())
    }
    useEffect(() => {
        if(props.configData['validations']&&props.configData['validations'][props.k]&&props.configData['validations'][props.k]['type']=='ENUM'){
            getOptions(props.configData['validations'][props.k])
            // validateENUM(props.value,props.configData['validations'][props.k])
        }
    }, []);
    useEffect(()=>{
            if(props.configData['validations']&&props.configData['validations'][props.k]&&props.configData['validations'][props.k]['type']=='ENUM'){
                validateENUM(props.value,props.configData['validations'][props.k])
            }
            if(props.configData['validations']&&props.configData['validations'][props.k]&&props.configData['validations'][props.k]['type']=='STRING'){
                validateString(props.value,props.configData['validations'][props.k])
            }
            if(props.configData['validations']&&props.configData['validations'][props.k]&&(props.configData['validations'][props.k]['type']=='INTEGER'||props.configData['validations'][props.k]['type']=='NUMBER')){
                validateInteger(props.value,props.configData['validations'][props.k])
            }
            if(props.configData['validations']&&props.configData['validations'][props.k]&&props.configData['validations'][props.k]['type']=='EMAIL'){
                validateEmail(props.value,props.configData['validations'][props.k])
            }
    },[])
return(
    <>
    {props.configData['validations']&&props.configData['validations'][props.k]&&props.configData['validations'][props.k]['type']=='STRING'?
    <>
    <input className="form-control" onChange={(e) => { props.updateKey(props.index, e.target.value, props.k);validateString(e.target.value,props.configData['validations'][props.k]) }} type="text" defaultValue={props.value} maxLength={props.configData['validations'][props.k]['max_length']||null} />
    {props.showError[props.k]&&props.showError[props.k]['required']?
    <span style={{color:'red',fontSize:'smaller'}}>This field is mandatory.</span>:
    props.showError[props.k]&&props.showError[props.k]['regex']?
    <span style={{color:'red',fontSize:'smaller'}}>Type a valid value.</span>:
    props.showError[props.k]&&props.showError[props.k]['min']?
    <span style={{color:'red',fontSize:'smaller'}}>Minimum {props.configData['validations'][props.k]['min_length']} charcters</span>
     :<span >&nbsp;</span>}
    </>
    :props.configData['validations']&&props.configData['validations'][props.k]&&(props.configData['validations'][props.k]['type']=='INTEGER'||props.configData['validations'][props.k]['type']=='NUMBER')?
    <>
    <input className="form-control" onChange={(e) => {props.updateKey(props.index, e.target.value, props.k);validateInteger(e.target.value,props.configData['validations'][props.k]) }} type="number" defaultValue={props.value} />
    {props.showError[props.k]&&props.showError[props.k]['required']?
    <span style={{color:'red',fontSize:'smaller'}}>This field is mandatory</span>:
    props.showError[props.k]&&props.showError[props.k]['type']?
    <span style={{color:'red',fontSize:'smaller'}}>Only Integer value is allowed</span>:
    props.showError[props.k]&&props.showError[props.k]['min']?
    <span style={{color:'red',fontSize:'smaller'}}>Min {props.configData['validations'][props.k]['min_value']}</span>
    :props.showError[props.k]&&props.showError[props.k]['max']?
    <span style={{color:'red',fontSize:'smaller'}}>Max {props.configData['validations'][props.k]['max_value']}</span>
     :<span >&nbsp;</span>}
    </>
    :props.configData['validations']&&props.configData['validations'][props.k]&&props.configData['validations'][props.k]['type']=='EMAIL'?
    (<>
    <input className="form-control" onChange={(e) => { props.updateKey(props.index, e.target.value, props.k);validateEmail(e.target.value,props.configData['validations'][props.k]) }} type="email" defaultValue={props.value} maxLength={props.configData['validations'][props.k]['max_length']||null} />
    {props.showError[props.k]&&props.showError[props.k]['required']?
    <span style={{color:'red',fontSize:'smaller'}}>This field is mandatory</span>
    :
    props.showError[props.k]&&props.showError[props.k]['type']?
    <span style={{color:'red',fontSize:'smaller'}}>Type valid email address</span>
     :<span >&nbsp;</span>}
     </>
    )
    :props.configData['validations']&&props.configData['validations'][props.k]&&props.configData['validations'][props.k]['type']=='ENUM'?
    <>
    <select onChange={(e) => { validateENUM(e.target.value,props.configData['validations'][props.k]);props.updateKey(props.index, e.target.value, props.k)}} className="form-control">
        {selectOptions.map((n)=>
        <option value={n} selected={n==props.value?true:false}>{n}</option>
        )}
    </select>
    {props.showError[props.k]&&props.showError[props.k]['required']?
    <span style={{color:'red',fontSize:'smaller'}}>This field is mandatory</span>
    :<span>&nbsp;</span>}
    </>
    :
    <>
    <input className="form-control" onChange={(e) => { props.updateKey(props.index, e.target.value, props.k) }} type="text" defaultValue={props.value} />
    <span>&nbsp;</span>
    </>
}
    </>
)
}
export default GenericInput