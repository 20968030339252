import React,{Component} from 'react';


export default class HomeTotal extends Component {

render(){
  return(
  <div className="Level fadeInUp"
  style={{'animationDelay': '0.8s',"display":"flex",'width':'100%'}}>
  <div className="level-item is-green"
    style={(this.props.strc==='')?{'display':'none'}:{"display":"flex",
  "flexDirection":"row","background":"#e4f4e8"}}>
  <h1 className="heading" style={{"color":"#74c687","marginRight":"7px"}}>
    {`${this.props.strc}: `}</h1>
  <h1 className="title has-text-success"
    style={{'marginTop':'9px','color':'#29a746'}}>
    {(Object.keys(this.props.pagination).length)?this.props.pagination.total:
      this.props.data.length}</h1>
  </div>
  {
    this.props.pttl.map((a,i)=>{
      if(a!=='')return (
        <div className="level-item" key={i} style={{"display":"flex",
        "flexDirection":"row","background":"#e4f4e8"}}>
        { (i>=this.props.ctitles.length)?(
            <h1 className="heading" style={{"color":"#74c687","marginRight":"7px"}}>
            {`Total ${this.props.shwt[i-this.props.ctitles.length]}: `}</h1>
          ):
            <h1 className="heading" style={{"color":"#74c687","marginRight":"7px"}}>
            {`Total ${this.props.ctitles[i]}: `}</h1>
      }
          <h1 className="title has-text-success"
          style={{"color":"#29a746"}}>{a}</h1>
        </div>);
      return '';
    })
  }
  </div>
    );
  }
}






// {
//   this.props.pttl.map((a,i)=>{
//     if(a!=='')return (
//       <div className="level-item" key={i} style={{"display":"flex",
//       "flexDirection":"row","marginRight":"5px","paddingTop":"7px",
//       "background":"#f6f6f7","width":"200px"}}>
//         <h1 className="heading" style={{"color":"#a5aaaf","marginRight":"7px"}}>
//         {`Total ${this.props.ctitles[i]}: `}</h1>
//         <h1 className="title has-text-success"
//         style={{"color":"#757e85"}}>{a}</h1>
//       </div>);
//     return '';
//   })
// }
