import React, {useState} from 'react';
const QueueButton = () => {
    return (
        <button type="button" class="btn btn-outline-info">
            QUEUED
        </button>
    )
}
const RetryButton = (props) => {
    const[showClipboard,setShowClipboard]=useState(true)
    return (
        <>
          {
              props.errorMsg[props.index]?
              <>
            <button style={{ marginRight: '5px' }} title={props.errorMsg[props.index]['msg'] || 'An Error has occured'} type="button" class="btn btn-outline-danger">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                </svg>
            </button>
            <button title={props.errorMsg[props.index]['msg'] || 'An Error has occured'} type="button" onClick={() => { props.setShowInput(true) }} class="btn btn-outline-primary">Retry</button>
            <button title={'Help Desk Email'} style={{marginLeft:'5px'}} onClick={()=>{window.open("mailto:"+props.errorMsg[props.index]['helpdesk_email']+"?subject=I am facing an issue with data upload.&body=Request Details:"+JSON.stringify(props.errorMsg[props.index]['curlData']))}} type="button" class="btn btn-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"></path>
                </svg>
            </button>   <button title={showClipboard?"Copy error to clipboard":"Error copied!"} className="btn btn-secondary" style={{marginLeft: '5px'}} onClick={()=>{navigator.clipboard.writeText(JSON.stringify(props.errorMsg[props.index]['curlData']));setShowClipboard(false)}} >
                        {showClipboard ?
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                </svg>
                            </span> :
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                </svg>
                            </span>
                        }
                        </button>
            </>:null
            }
    </>
    )
}
const SuccessIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#28a745" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>
    )
}
const Spinner = () => {
    return (
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    )
}
export {Spinner,SuccessIcon,RetryButton,QueueButton}