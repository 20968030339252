import "jspdf-autotable";

import * as FileSaver from "file-saver";

import React, { Component, useEffect, useState } from "react";

import Calculation from "./Calculation";
import _ from "lodash";
import axios from "axios";
import jsPDF from "jspdf";
import moment from 'moment';

const Excel = require('exceljs');
const doc = new jsPDF({
  orientation: "landscape",
});

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

let arr = [],
  pf = {},
  ttl = [],
  nst = [];
export default class HomeLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      token: "",
      hideExcelHeader: '',
      apiUrl: "",
      fullData: [],
      fbct: "",
      data_key: "",
      send_token_in_header: undefined,
      fbcl: "",
      ckeys: [],
      ctitles: [],
      shwt: [],
      decim: "",
      downloadHide: "",
      showCount: "",
      data: []
    };
    this.onClick = this.onClick.bind(this);
    this.exc = this.exc.bind(this);
  }

  async componentDidMount() {
    await this.setState({
      title: this.props.title,
      apiUrl: this.props.apiUrl,
      ctitles: this.props.ctitles,
      ckeys: this.props.ckeys,
      data_key: this.props.data_key,
      shwt: this.props.shwt,
      hideExcelHeader: this.props.hideExcelHeader,
      send_token_in_header: this.props.send_token_in_header,
      token: this.props.token,
      decim: this.props.decim,
      downloadHide: this.props.downloadHide,
      fbct: this.props.fbct,
      fbcl: this.props.fbcl,
      showCount: this.props.showCount,
      report_timezone:this.props.report_timezone,
      showtimestamp:this.props.showtimestamp,
      data: this.props.data || []
    });
    this.forceUpdate();
  }

  basicCalc = () => {
    if (this.props.fbcl) {
      if (this.props.fbcl.includes(",")) {
        this.setState({
          fullData: Calculation(
            this.state.fullData,
            this.props.fbcl.split(","),
            this.props.fbct.split(",")
          ),
        });
      } else {
        this.setState({
          fullData: Calculation(
            this.state.fullData,
            [this.props.fbcl],
            [this.props.fbct]
          ),
        });
      }
    }
  };

  showCount = () => {
    let arr = [],
      o = {};
    this.state.fullData.forEach((a, i) => {
      o = a;
      o["#"] = i + 1;
      arr.push(o);
    });
    this.setState({ fullData: arr });
  };

  async onClick() {
    let j,
      x,
      k,
      b = [],
      o = {},
      i = 0;
    if (this.state.token !== "") {
      await axios({
        url: decodeURIComponent(`${this.state.apiUrl}`+ (this.state.send_token_in_header ?'' :`&token=${this.state.token}`)),
        headers: {
          Authorization: this.state.send_token_in_header ? this.state.token : undefined
        }
      })
        .then((res) =>
          this.setState({ fullData: _.get(res.data,this.state.data_key), isLoad: false })
        )
        .catch((err) => console.log(err));
    } else {
      await axios({
        url: decodeURIComponent(this.state.apiUrl)
      })
        .then((res) =>
          this.setState({ fullData: _.get(res.data,this.state.data_key), isLoad: false })
        )
        .catch((err) => console.log(err));
    }

    if (this.state.decim !== "") {
      let obj,
        k,
        samp = this.state.fullData;
      for (obj = {}, i = 0; i < samp.length; ++i) {
        obj = samp[i];
        for (k in obj) {
          if (typeof obj[k] === "number") {
            if (obj[k] % 1)
              samp[i][k] = obj[k].toFixed(parseInt(this.state.decim));
          }
        }
      }
      this.setState({ fullData: samp });
    }

    if(this.state.showCount!=='')this.showCount();
    this.basicCalc();

    nst = [];
    ttl = [];
    this.state.fullData.forEach((b) => {
      this.state.ckeys.forEach((a) => {
        if (a.includes(".")) {
          k = a.split(".");
          if (k && k[0] in b) {
            x = b[k[0]];
            for (j = 1; j < k.length; ++j) {
              if (x && (k[j] in x)) x = x[k[j]];
              else x = "";
            }
            nst.push(x);
          } else nst.push("");
        } else nst.push(b[a]);
      });
    });
    const style2 = {
      font: { sz: "12" },
      alignment: { wrapText: true, vertical: "bottom", horizontal: "center" },
    };
    arr = [];
    arr.push({ columns: this.state.ctitles });
    arr[0]["data"] = [];
    this.state.fullData.forEach((q1, i) => {
      b = [];
      this.state.ckeys.forEach((q2, j) => {
        if (q2.includes(".")) {
          o = {};
          o["value"] = nst[i * (nst.length / this.state.fullData.length) + j];
          if (!i)
            o["style"] = {
              font: { sz: "12" },
              alignment: {
                wrapText: true,
                vertical: "bottom",
                horizontal: "center",
              },
              border: {
                top: { style: "medium", color: { rgb: "#000000" } },
              },
            };
          else o["style"] = style2;
          b.push(o);
        } else {
          o = {};
          o["value"] = q1[q2]
            ? q1[q2] < 0
              ? `-${Math.abs(q1[q2])}`
              : q1[q2].toString()
            : "";
          if (!i)
            o["style"] = {
              font: { sz: "12" },
              alignment: {
                wrapText: true,
                vertical: "bottom",
                horizontal: "center",
              },
              border: {
                top: { style: "medium", color: { rgb: "#000000" } },
              },
            };
          else o["style"] = style2;
          b.push(o);
        }
      });
      arr[0]["data"].push(b);
      ++i;
    });
    this.state.shwt.forEach((b) => {
      const f = this.state.ckeys.indexOf(b);
      if (f !== -1) {
        ttl[f] = 0;
        this.state.fullData.forEach((a) => {
          let path_components = b.split('.');
          let value = a;
          for (var i = 0; i < path_components.length; i++) {
            let component = path_components[i];

            if (typeof value[component] === "object") {
              value = value[component];
            } else if (typeof value[component] === "undefined") {
              value = 0;
            } else if (typeof value[component] === "string") {
              value = parseFloat(value[component].replace(",", "")) || 0;
            } else {
              value = parseFloat(value[component]) || 0;
            }
          }
          ttl[f] += value;

        });
        if (typeof ttl[f] === "object") {
            ttl[f] = 0;
        }
        ttl[f] = ttl[f].toFixed(2);
        if(ttl[f]-parseInt(ttl[f])===0)ttl[f]=parseInt(ttl[f]);
      }
    });
    for (i = 0; i < ttl.length; ++i) {
      ttl[i] = ttl[i] || "";
    }
    for (; i < this.state.ctitles.length; ++i) {
      ttl[i] = "";
    }
    for (i = 0; i < ttl.length; ++i) {
      if (ttl[i] !== "") {
        if (ttl[i] < 0) {
          if (typeof ttl[i] === "string")
            ttl[i] = `-${Math.abs(ttl[i].replace(",", ""))}`;
          else ttl[i] = `-${Math.abs(ttl[i])}`;
        } else ttl[i] = ttl[i].toString();
      }
    }
    if (this.state.shwt.length>0) {
      for (b = [], i = 0; i < ttl.length; ++i) {
        o = {};
        o["value"] = ttl[i] !== "" ? `${ttl[i]}` : ttl[i];
        o["style"] = {
          font: { sz: "12", bold: true },
          alignment: {
            wrapText: true,
            vertical: "bottom",
            horizontal: "center",
          },
          border: {
            top: { style: "medium", color: { rgb: "#000000" } },
          },
        };
        b.push(o);
      }
      arr[0]["data"].push(b);
    }
    this.forceUpdate();

    pf = {};
    pf["head"] = [];
    pf["body"] = [];
    pf["foot"] = [];
    pf["head"].push(arr[0]["columns"]);
    x = arr[0].data.pop();
    arr[0].data.forEach((a) => {
      b = [];
      a.forEach((c) => {
        b.push(c["value"]);
      });
      pf["body"].push(b);
    });
    b = [];
    x.forEach((c) => {
      b.push(c["value"]);
    });
    pf["foot"].push(b);
    pf["showFoot"] = "lastPage";
    pf["styles"] = {
      fontSize: 8,
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
      minCellWidth: 10,
    };
    pf["headStyles"] = {
      fillColor: [76, 75, 75],
      fontStyle: "bold",
      fontSize: 9,
    };
    pf["footStyles"] = {
      fillColor: this.state.shwt.length>0? [76, 75, 75]:[256,256,256],
      fontStyle: this.state.shwt.length>0?"bold":"normal",
      fontSize: this.state.shwt.length>0?9:8,
      textColor:this.state.shwt.length>0?[256,256,256]:[0,0,0]
    };
    pf["bodyStyles"] = { textColor: [0, 0, 0] };
    try{
      let titleLocal = this.state.title
      pf["didDrawPage"] = function (data) {
        doc.setFontSize(20)
        doc.setFontType("bold")
        doc.setTextColor(40)
        var splitTitle = doc.splitTextToSize(titleLocal, 250);
        doc.text(15,25,splitTitle)
      }
      if(titleLocal.length){
        pf["margin"] = {top: (parseInt(titleLocal.length / 69 ) * 10) + 30 };
      }else{
        pf["margin"] = {top: 30};
      }
      doc.autoTable(pf);
      doc.save(this.state.title.length>80 ? this.state.title.substring(0,79):this.state.title);
    }catch(error){
      doc.autoTable(pf);
      doc.save(this.state.title.length>80 ? this.state.title.substring(0,79):this.state.title);
    }
    window.location.reload();
  }

  async exc() {
    let csvData = [],
      o = {},
      fileName = (this.state.title.length>80 ? this.state.title.substring(0,79):(this.state.title || "table")) || "table",
      i = 0;
    nst = [];
    ttl = [];
    if (this.state.token !== "") {
      await axios({
        url: decodeURIComponent(
          `${this.state.apiUrl}` + (this.state.send_token_in_header ? '' : `&token=${this.state.token}`),
        ),
        headers: {
          Authorization: this.state.send_token_in_header ? this.state.token : undefined
        }
      })
        .then((res) =>
          this.setState({ fullData: _.get(res.data,this.state.data_key), isLoad: false })
        )
        .catch((err) => console.log(err));
    } else {
      await axios({
        url: decodeURIComponent(this.state.apiUrl),
      })
        .then((res) =>
          this.setState({ fullData: _.get(res.data,this.state.data_key), isLoad: false })
        )
        .catch((err) => console.log(err));
    }

    if (this.state.decim !== "") {
      let obj,
        k,
        samp = this.state.fullData;
      for (obj = {}, i = 0; i < samp.length; ++i) {
        obj = samp[i];
        for (k in obj) {
          if (typeof obj[k] === "number") {
            if (obj[k] % 1)
              samp[i][k] = obj[k].toFixed(parseInt(this.state.decim));
          }
        }
      }
      this.setState({ fullData: samp });
    }

    if(this.state.showCount!=='')this.showCount();
    this.basicCalc();

    let j, x, k;
    this.state.fullData.forEach((b) => {
      this.state.ckeys.forEach((a) => {
        if (a.includes(".")) {
          k = a.split(".");
          if (k && k[0] in b) {
            x = b[k[0]];
            for (j = 1; j < k.length; ++j) {
              if (x && (k[j] in x)) x = x[k[j]];
              else x = "";
            }
            nst.push(x);
          } else nst.push("");
        } else nst.push("");
      });
    });
    this.state.fullData.forEach((a, i) => {
      let c = [];
      this.state.ckeys.forEach((b, j) => {
        let o
        if (b.includes("."))
          o =
            nst[i * (nst.length / this.state.fullData.length) + j];
        else o = a[b] || "";
        c.push(o)
      });
      csvData.push(c);
    });

    this.state.shwt.forEach((b) => {
      const f = this.state.ckeys.indexOf(b);
      if (f !== -1) {
        ttl[f] = 0;
        this.state.fullData.forEach((a) => {
          if (typeof a[b] === "string")
            ttl[f] += parseFloat(a[b].replace(",", "")) || 0;
          else ttl[f] += parseFloat(a[b]) || 0;
        });
        ttl[f] = ttl[f].toFixed(2);
        if(ttl[f]-parseInt(ttl[f])===0)ttl[f]=parseInt(ttl[f]);
      }
    });
    for (i = 0; i < ttl.length; ++i) {
      ttl[i] = ttl[i] || "";
    }
    for (; i < this.state.ctitles.length; ++i) {
      ttl[i] = "";
    }
    for (i = 0; i < ttl.length; ++i) {
      if (ttl[i] !== "") {
        if (ttl[i] < 0) {
          if (typeof ttl[i] === "string")
            ttl[i] = `-${Math.abs(ttl[i].replace(",", ""))}`;
          else ttl[i] = `-${Math.abs(ttl[i])}`;
        } else ttl[i] = ttl[i].toString();
      }
    }
    let footer=[]
    if (this.state.shwt.length) {
      this.state.shwt.forEach((a) => {
        const f = this.state.ckeys.indexOf(a);
        if (f !== -1) {
           footer.push(ttl[f] !== "" ? `Total ${this.state.ctitles[f]}: ${ttl[f]}` : ttl[f]);
        }
      });
    }
    // let t=[]
    // let header=[]
    //   for (let i = 0; i < csvData.length; i++) {
    //     let d=[]
    //     for(var n in csvData[i]) {
    //       d.push(csvData[i][n]);
    //       if(i==0)
    //       header.push(n)
    //    }
    //    t.push(d)
    //   }
    this.forceUpdate();
     
    let wb = new Excel.Workbook();
    let worksheet = wb.addWorksheet('Sheet');

    let excelHeader = false;
    if(this.state){
      excelHeader = this.state.hideExcelHeader ?? false
    }

    if(!excelHeader){
      let titleRow = worksheet.addRow([this.state.title]);
      titleRow.font = { name: 'Times New Roman', family: 4, size: 20, underline: 'double', bold: true };
      worksheet.addRow([]);
      if(this.state.showtimestamp!=0){
        let date =new Date().toLocaleString('en-GB', {
          timeZone: this.state.report_timezone
        })
      let subTitleRow = worksheet.addRow(['As on Date : ' +moment(date,'DD/MM/YYYY').format('MMMM D,yyyy')+' '+new Date().toLocaleTimeString(undefined, {
        timeZone: this.state.report_timezone
      })+` (Timezone: ${this.state.report_timezone})`]);
       }
       worksheet.addRow([]);
       worksheet.mergeCells('A1:D2');
    }

    let headerRow = worksheet.addRow(this.state.ctitles);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    csvData.forEach(d => {
      let row = worksheet.addRow(d);
      try {
        row.eachCell(n=>{
          n.style.alignment = { wrapText: true }
        })
      }catch (err){

      }
    }
    );
    if(footer.length>0){
      worksheet.addRow([]);
      let footerRow = worksheet.addRow(footer);
      footerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    }
    try{
      worksheet.columns.forEach(col=>{
        col.width = 25
      })
    }catch(err){

    }
    wb.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, fileName + fileExtension);
    });


    // const ws = XLSX.utils.json_to_sheet(csvData);
    // const wb = { Sheets: { [fileName.substring(0,31)]: ws }, SheetNames: [fileName.substring(0,31)] };
    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // const data = new Blob([excelBuffer], { type: fileType });
    // FileSaver.saveAs(data, fileName + fileExtension);
  }

  render() {
    return (
      <div
        className="header fadeInUp"
        style={{ animationDelay: "0.5s", width: "100%" }}
      >
        <div className="header-mid">
          <div
            className="titles"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h1 id="aise" style={{ color: "#343a40" }}>
              {this.state.title}
            </h1>

          {this.state.data?.length > 0 &&
            <>
              <button
                className="btn ml-4 btn-sm"
                onClick={this.exc}
                style={
                  this.state.downloadHide === "true"
                    ? { display: "None" }
                    : {
                        fontWeight: "600px",
                        borderTop: "0",
                        color: "rgb(254,169,98)",
                        background: "rgb(252,238,227)",
                      }
                }
              >
                <i className="fa fa-download mr-1" aria-hidden="true"></i>
                Download Excel
              </button>
              <DownloadPDFButton onPress={this.onClick} downloadHide={this.state.downloadHide} />
            </>
            }
          </div>
        </div>
      </div>
    );
  }
}


const DownloadPDFButton = ({downloadHide,onPress})=>{
  
  const [finalHide,setFinalHide] = useState(false)
  useEffect(() => {
    let urlParams = null
    let hashURL = window.location.hash
    if(hashURL=='#/'){
      urlParams = new URLSearchParams(window.location.search)
    }else{
      urlParams = new URLSearchParams('?'+hashURL.split('?')[1])
    }
    if(urlParams.get('hidePDFBtn') == true || urlParams.get('hidePDFBtn') == 'true' ||  downloadHide === "true"){
      setFinalHide(true)
    }
  })
  return             <button
  className="btn ml-4 btn-sm"
  onClick={onPress}
  style={
    finalHide
      ? { display: "none" }
      : {
          fontWeight: "600px",
          borderTop: "0",
          color: "rgb(254,169,98)",
          background: "rgb(252,238,227)",
        }
  }
>
  <i className="fa fa-download mr-1" aria-hidden="true"></i>
  Download Pdf
</button>
}