/* eslint no-eval: 0 */

const convertstr=exp=>{
  let i,ch='';
  for(i=0;i<exp.length;++i){
    if(exp[i]==='%'&&exp[i+1]==='2'&&exp[i+2]==='0'){
      ch+='+';
      i+=2;
    }
    else if(exp[i]==='%'&&exp[i+1]==='2'&&exp[i+2]==='F'){
      ch+='/';
      i+=2;
    }
    else if(exp[i]==='%'&&exp[i+1]==='5'&&exp[i+2]==='E'){
      ch+='^';
      i+=2;
    }
    else ch+=exp[i];
  }
  return ch;
}

const calculate=(data,exp,title)=>{
let ch=[],i=0;
for(i=0;i<exp.length;ch.push(convertstr(encodeURIComponent(exp[i]))),++i);

title.forEach((t,ti)=>{
  data.forEach(d=>{
    let a='',b='',j,k,x,y;
    for(i=0;i<ch[ti].length;++i){
      if(
      ch[ti][i]!=='('&&ch[ti][i]!==')'&&ch[ti][i]!=='+'&&ch[ti][i]!=='-'&&
      ch[ti][i]!=='*'&&ch[ti][i]!=='/'&&ch[ti][i]!=='^'&&
      (!(ch[ti][i]>='0'&&ch[ti][i]<='9'))
  ){
      for(b='',j=i;(
      ch[ti][j]!=='('&&ch[ti][j]!==')'&&
      ch[ti][j]!=='+'&&ch[ti][j]!=='-'&&ch[ti][j]!=='*'
      &&ch[ti][j]!=='/'&&ch[ti][j]!=='^'&&
    !(ch[ti][j]>='0'&&ch[ti][j]<='9')
  );++j){
        b+=ch[ti][j];
      }
      i=j-1;
      if(b.includes('.')){
        k=b.split('.');
        if(k&& (k[0] in d))
          {
            x=d[k[0]];
            for(y=0,j=1;j<k.length;++j){
              if(k[j] in x){
                x=x[k[j]];
              }
              else{
                 y=1;break;
               }
            }
            if(y){
              a='0';break;
            }
            else a+=x;
          }
        else{
          a='0';
          break;
        }
      }
      else a+=(d[b]||'');
      }
      else a+=ch[ti][i];
    }
    d[t]=eval(a);
  });
});
return data;
}

export default calculate;
