import React, { useEffect, useState,useRef } from 'react';
import axios from "axios";
import $ from 'jquery';
import './bulkUpload.css';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Table from '../shared/table';
const csvjson = require('csvtojson'); 
const BulkUpload = () => {
    const [queryParam, setqueryParam] = useState({
        configurl: null,
        token: null,
        helpdesk_email:null
    })
    const [saveRowfnc, SetSaveRowfnc] = useState({})
    const [configData, setConfigData] = useState({})
    const [state, setState] = useState([])
    const [header, setHeader] = useState([])
    const [showTable, setShowTable] = useState(false)
    const [errorMsg,setErrorMsg]=useState({})
    const [csvFile,setCsvFile]=useState({
        file:'',
        fileName:''
    })
    const [count, setCount] = useState({
        total: 0,
        processing: 0,
        failed: 0,
        success: 0
    })
    const [randomKey, setRandomKey] = useState('jcucuseth')
    function getParameterByName(name, url) {
        if (!url) url = window.location.href.href;
        name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    const getQueryParams = () => {
        const url = new URL(window.location);
        setqueryParam({
            configurl: getParameterByName('configurl',url.href),
            token: getParameterByName('token',url.href),
            helpdesk_email:getParameterByName('helpdesk_email',url.href),
            session_id:getParameterByName('session_id',url.href)
        })
        axios({
            url: decodeURIComponent(
                getParameterByName('configurl',url.href)
            )
        })
            .then((response) => {
                if (response.data['response']['data']) {
                    setConfigData(response.data['response']['data'])
                } else {
                    console.log('error')
                }
            })
            .catch((error) => {
                console.log('error')
            });
    }
    const filePathset = (e) => {
        e.stopPropagation();
        e.preventDefault();
        
        var file = e.target.files[0];
        console.log(file);
        readFile(file)
    }

    const readFile = (f) => {
        var name = f.name;
        const reader = new FileReader();
        reader.onload = async (evt) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1});
            var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
            
            let d = await csvjson().fromString(data);
            
            var lines = data.split("\n");
    
            var headers = []
            let n=lines[0].split(",");
            for (let i = 0; i < n.length; i++) {
            if(configData['json_mapping'][n[i]]){
             headers.push(n[i])
            }
            }
          
            setHeader(headers)

            
            setCsvFile({
                file:wbout,
                fileName:f.name
            })
            if (configData['max_allowed_rows'] && d.length > configData.max_allowed_rows) {
                window.alert('Max allowed rows are ' + configData.max_allowed_rows + '.')
                    document.getElementById('uploadInput').value = null
                    return
            } else {
                setShowTable(true)
                loadTabs()
                setState(d)
                SetSaveRowfnc({ a: true })
                getCount(d)
            }
        };
        reader.readAsBinaryString(f);
    }

    const getCount = (data) => {
        let a = 0, b = 0, c = 0, d = 0
        data.forEach(element => {
            if (element.___status == 'queue') {
                a = a + 1
            }
            if (element.___status == 'processing') {
                b = b + 1
            }
            if (element.___status == 'success') {
                c = c + 1
            }
            if (element.___status == 'failed') {
                d = d + 1
            }
        });
        setCount({
            total: data.length,
            queue: a,
            processing: b,
            success: c,
            failed: d
        })


    }
    const loadTabs = () => {
        $('#material-tabs').each(function () {

            var $active, $content, $links = $(this).find('a');

            $active = $($links[0]);
            $active.addClass('active');

            $content = $($active[0].hash);

            $links.not($active).each(function () {
                $(this.hash).hide();
            });

            $(this).on('click', 'a', function (e) {

                $active.removeClass('active');
                $content.hide();

                $active = $(this);
                $content = $(this.hash);

                $active.addClass('active');
                $content.show();

                e.preventDefault();
            });
        });
    };
    const saveRow =async() => {
        for (let i = 0; i < state.length; i++) {
            await callApi(i)
        }
    }
    const updateStatus = (index, value) => {
        var _tempArray = state
        _tempArray[index]['___status'] = value
        setState(_tempArray)
        getCount(_tempArray)
        setRandomKey(new Date().getMilliseconds())
    }
    const callApi = async (i) => {
        var body = {}
        if (configData['constants']) {
            for (let j = 0; j < configData.constants.length; j++) {
                body[configData['constants'][j]['lhs']] = configData['constants'][j]['rhs']
            }
        }
        for (var key in state[i]) {
            if(key!='___status'){
                body[configData.json_mapping[key]] = state[i][key]
            } 
        }
        let h = {
            'Content-Type': 'application/json'
        }
        if (configData['auth_enabled']) {
            h['Authorization'] = configData['remove_bearer']?'':'bearer '
            h['Authorization']+=queryParam['token']
        }
        if(queryParam['session_id']){
            h['x-session-id']=queryParam['session_id']
        }
        updateStatus(i, 'processing')
        const response = await fetch(configData.endpoint, {
            method: configData['method'] ? configData['method'] : 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: h,
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(body)
        }).then(response => {
            const json=response.json()
            if (response.status == 200 && response.ok) {
                updateStatus(i, 'success')
            } else {
                updateStatus(i, 'failed')
            }
            return json
        }).then(data => {
                let x = errorMsg
                x[i]={}
                x[i]['msg'] = data['msg']
                x[i]['helpdesk_email']=queryParam['helpdesk_email']
                x[i]['curlData'] = {
                    'Method':configData['method'] ? configData['method'] : 'POST',
                    'Endpoint':configData['endpoint'],
                    'Request body':body,
                    'Header':h,
                    'Message':data['msg']
                }
                setErrorMsg(x)
                setRandomKey(new Date().getMilliseconds())
            })
            .catch((error) => {
                console.log(error)
                updateStatus(i, 'failed')
                let x = errorMsg
                x[i]={}
                x[i]['msg'] = error
                x[i]['helpdesk_email']=queryParam['helpdesk_email']
                x[i]['curlData'] = {
                    'Method':configData['method'] ? configData['method'] : 'POST',
                    'Endpoint':configData['endpoint'],
                    'Request body':body,
                    'Header':h,
                    'Message':error
                }
                setErrorMsg(x)
                setRandomKey(new Date().getMilliseconds())
            });
    }
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    const downloadCsv=()=>{
        let blob = new Blob([s2ab(csvFile['file'])], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob,csvFile['fileName']);
    }
    useEffect(() => {
        getQueryParams()
    }, [])
    useEffect(() => {
        saveRow()
    }, [saveRowfnc]);
    return (
        <>
            {!showTable ?
                <div className="container fluid buttonWrapper" >
                    <div className="d-inline-flex p-2" style={{ flexDirection: 'column' }} >
                        <button type="button" class="btn btn-outline-dark mb-3" onClick={() => { document.getElementById('uploadInput').click() }} >Upload Excel</button>
                        <input onChange={(e) => { filePathset(e) }} type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden id="uploadInput" />
                        <button type="button" class="btn btn-outline-dark" onClick={() => { window.open(configData['sample_excel_link'], '_blank') }}>Download Template</button>
                    </div>
                    {configData['guidelines'] ?
                        <div className="card" style={{marginTop:'20px'}}>
                            <div class="card-body" style={{borderBottom: '1px solid rgba(0,0,0,.125)',justifyContent:'center',display:'flex'}}>
                              <img style={{width:'20px'}} src={require('./info-circle-solid.svg')} />
                              <strong>&nbsp;Guidelines</strong>
                            </div>
                            <ul className="list-group list-group-flush">
                                {configData['guidelines'].map(item => {
                                    return (
                                        <>
                                            <li className="list-group-item">{item}</li>
                                        </>
                                    )
                                })}
                            </ul>
                        </div> : null}
                </div>
                :
                <Table downloadCsv={downloadCsv} configData={configData} errorMsg={errorMsg} callApi={callApi} randomKey={randomKey} count={count} state={state} setState={setState} header={header} />}
        </>
    )
}

export default BulkUpload;
