import React,{Component} from 'react';

export default class Loader extends Component {
  constructor(props){
    super(props);
    this.state = {
      isLoad:null
    };
  }

  componentDidMount(){
    this.setState({isLoad:this.props.isLoad});
  }

  render(){
    return(
      <div style={{'display':'flex','height':'100vh',
        'alignItems':'center','justifyContent':'center'}}>
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw" id='loader'></i>
    </div>
    );
  }
}
