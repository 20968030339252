import React, { useEffect, useState } from "react";
import axios from "axios";

const GenericReports = (props) => {
  const [apiresponse, setapiresponse] = useState("LOADING");
  const [errorMsg, seterrorMsg] = useState();
  function getParameterByName(name, url) {
    if (!url) url = window.location.href.href;
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
  useEffect(() => {
    let url = "https://auth2.simplifii.com/getReportUrl?";
    let searchParams = new URL(window.location);
    let uid = getParameterByName("uid", searchParams.href);
    let token = getParameterByName("token", searchParams.href);
    let report_id = getParameterByName("report_id", searchParams.href);
    let DEFAULTERRORMSG = "Something went wrong, Please try again later.";
    if (report_id) {
      url = url + `report_id=${report_id}&`;
    }
    if (uid) {
      url = url + `uid=${uid}&`;
    }
    if (token) {
      url = url + `token=${token}&`;
    }

    axios(url.substring(0, url.length - 1))
      .then((response) => {
        if (response.data.response.data &&response.data.response.data.report_url) {
          window.location.href = response.data.response.data.report_url;
        } else {
          setapiresponse("Error");
          seterrorMsg(DEFAULTERRORMSG);
        }
      })
      .catch((error) => {
        setapiresponse("Error");
        seterrorMsg(
          error.error ? error.error["msg"] || DEFAULTERRORMSG : DEFAULTERRORMSG
        );
      });
  }, []);
  return (
    <>
      {apiresponse === "LOADING" ? (
        <div
          style={{
            display: "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i className="fa fa-spinner fa-pulse fa-3x fa-fw" id="loader"></i>
        </div>
      ) : apiresponse === "Error" ? (
        <h4 style={{ textAlign: "center", paddingTop: "20px" }}>{errorMsg}</h4>
      ) : (
        <h3 style={{ textAlign: "center", paddingTop: "20px" }}>
          Something went wrong
        </h3>
      )}
    </>
  );
};
export default GenericReports;
