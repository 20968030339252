import React,{Component} from 'react';

export default class Errorapi extends Component {
  render(){
    return(
      <div>
       <div className="modal-wrapper">
       <div className="modal-header">
       <img src='./images/as.png' width='75' height='75' alt='Unavailable'/>
       </div>
       <div className="modal-body">
       <p style={{'fontFamily':'Archia','fontSize':'18px'}}>{this.props.msg}</p>
       </div>
       </div>
     </div>
    );
  }
}
