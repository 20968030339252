import React, { useEffect, useState,useRef } from 'react';
import { RetryButton,QueueButton,SuccessIcon,Spinner } from '../shared/shared';
import GenericInput from './genericInput';
import { debounce } from 'lodash';
const Table = (props) => {
    return (
        <>
            <div id="table" class="container-fluid">
                <div>
                    <div id="material-tabs">
                        <a id="tab1-tab" href="#tab1" class="active">Total: {props.count.total}</a>
                        <a id="tab2-tab" href="#tab2">Processed: {props.count.success + props.count.failed} </a>
                        <a id="tab3-tab" href="#tab3">Success: {props.count.success}</a>
                        <a id="tab4-tab" href="#tab4">Failed: {props.count.failed}</a>
                        <span style={{ marginLeft: "auto" }}>Uploaded Excel&nbsp; <button type="button" class="btn btn-secondary" onClick={()=>{props.downloadCsv()}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
                            </svg>
                        </button></span>
                    </div>
                </div>
                <div class="tab-content bulkUploadTable" >
                    <div id="tab1" key="tab1">
                        <table class="table table-striped">
                            <thead>
                                <TableHeader key={'tableHeader'} header={props.header} state={props.state} />
                            </thead>
                            <tbody key={props.randomKey + 'tbody1'}>
                                {props.state.map((item, index) => (
                                    <TableRow configData={props.configData} errorMsg={props.errorMsg} key={index + 'tableRowTotal'} callApi={props.callApi} state={props.state} setState={props.setState} index={index} item={item} />
                                )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div id="tab2" key="tab2">
                        <table class="table table-striped">
                            <thead>
                                <TableHeader header={props.header} state={props.state} />
                            </thead>
                            <tbody key={props.randomKey + 'tbody3'}>
                                {
                                    props.state.map((item, index) => {
                                        return (
                                            <>
                                                {(item['___status'] == 'success' || item['___status'] == 'failed') ? <TableRow errorMsg={props.errorMsg} configData={props.configData} callApi={props.callApi} state={props.state} setState={props.setState} index={index} item={item} /> : null}
                                            </>
                                        )
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div id="tab3" key="tab3">
                        <table class="table table-striped">
                            <thead>
                                <TableHeader header={props.header} state={props.state} />
                            </thead>
                            <tbody key={props.randomKey + 'tbody2'}>
                                {
                                    props.state.map((item) => {
                                        return (
                                            <>
                                                {(item['___status'] == 'success') ? <TableRow item={item} /> : null}
                                            </>
                                        )
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div id="tab4" key="tab4">
                        <table class="table table-striped">
                            <thead>
                                <TableHeader header={props.header} state={props.state} />
                            </thead>
                            <tbody key={props.randomKey + 'tbody4'}>
                                {
                                    props.state.map((item, index) => {
                                        return (
                                            <>
                                                {item['___status'] == 'failed' ? <TableRow configData={props.configData} errorMsg={props.errorMsg} callApi={props.callApi} state={props.state} setState={props.setState} index={index} item={item} /> : null}
                                            </>
                                        )
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
const TableHeader = (props) => {
    const [randomKey2, setRandomKey2] = useState('randomKey2')
    return (
        <tr key={randomKey2 + 'tableHeader'}>
            {
                props.header.map((item, index) => (
                    <th scope="col">{item}</th>
                ))
            }
            <th scope="col">Status</th>
        </tr>
    )
}
const TableRow = (props) => {
    const [showInput, setShowInput] = useState(false)
    const [randomKey, setRandomKey] = useState('randomKey')
    const [isFormValid,setIsFormValid]=useState({})
    const updateKey = debounce((index, value, key) => {
        var _tempArray = props.state
        _tempArray[index][key] = value
        _tempArray[index]['___status'] = 'queue'
        props.setState(_tempArray)
    }, 500)
    const [showError, setShowError] = useState({})
    console.log('TableRow',showError)
    const saveForm=(i)=>{
        for(var key in showError){
            if(showError[key]){
                return
            }
        }
        setShowInput(false);
        props.callApi(i)
    }
    useEffect(() => {
        for(var key in props.item){
            if(key!='___status'){
            let n=showError
            n[key]=false
            setShowError(n)
        }
        }
    }, []);
    return (
        <tr key={randomKey + -'class' + props.index}>
            {
                Object.entries(props.item).map(([key, value]) => {
                    return (
                        <>
                            {
                                showInput
                                    ?
                                    <td key={randomKey + props.index}>
                                        {key == '___status' ?
                                            <><button className="btn btn-primary" type="submit" onClick={() => { saveForm(props.index); }}>Submit</button><br /><span>&nbsp;</span></> :
                                            <GenericInput isFormValid={isFormValid} showError={showError} setShowError={setShowError} updateKey={updateKey} index={props.index} k={key} value={value} configData={props.configData}/>
                                        }
                                    </td>
                                    : key == '___status' && value == 'processing' ? <td className="text-center">
                                        <Spinner />
                                    </td>
                                        : key == '___status' && value == 'queue' ?
                                            <td>
                                                <QueueButton />
                                            </td>
                                            : key == '___status' && value == 'success' ?
                                                <td class="text-center">
                                                    <SuccessIcon />
                                                </td> :
                                                key == '___status' && value == 'failed' ?
                                                    <td className="text-center retryTd">
                                                        <RetryButton errorMsg={props.errorMsg} index={props.index} setShowInput={setShowInput} />
                                                    </td> :
                                                    <td scope="col">{value}</td>
                            }
                        </>)
                })
            }
        </tr>
    )
}
export default Table